import { Injectable } from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {TenantsEnum} from "../enums/TenantsEnum";

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  streaksTenants = [TenantsEnum.streak, TenantsEnum.streakbrazil];

  specialMessageTenantSet = [TenantsEnum.superbetpoland, TenantsEnum.superbetromania];

  tenantArray = [TenantsEnum.napoleon, TenantsEnum.superbetbrazil];

  tenantId$: BehaviorSubject<string> = new BehaviorSubject('');

  isStreak$ = new BehaviorSubject<boolean>(null);

  isNoTenenat$ = new BehaviorSubject<boolean>(null);

  isNapoleon$ = new BehaviorSubject<boolean>(null);

  isSuperbetBrazil$ = new BehaviorSubject<boolean>(null);

  isSuperbetRomania$ = new BehaviorSubject<boolean>(null);

  isSuperbetPoland$ = new BehaviorSubject<boolean>(null);

  isStreakBrazil$ = new BehaviorSubject<boolean>(null);

  isSpecialMessageTenantSet$ = new BehaviorSubject<boolean>(null);

  constructor() {
    this.tenantId$.next(window['tenantId']);
    const tenantId = window['tenantId']
    this.isStreak$.next(this.streaksTenants.includes(tenantId));
    this.isNoTenenat$.next(!this.tenantArray.includes(tenantId));
    this.isNapoleon$.next(tenantId === TenantsEnum.napoleon);
    this.isSuperbetBrazil$.next(tenantId === TenantsEnum.superbetbrazil);
    this.isSuperbetRomania$.next(tenantId === TenantsEnum.superbetromania);
    this.isSuperbetPoland$.next(tenantId === TenantsEnum.superbetpoland);
    this.isStreakBrazil$.next(tenantId === TenantsEnum.streakbrazil);
    this.isSpecialMessageTenantSet$.next(tenantId === this.specialMessageTenantSet.includes(tenantId));
  }

}

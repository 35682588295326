import {Component} from '@angular/core';
import { environment } from "../../environments/environment";
import {LoaderControllerService} from "../core/services/loader-controller.service";

@Component({
  selector: 'hun-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {
  constructor(private loaderControllerService: LoaderControllerService) {
    this.loaderControllerService.isLoading$.next(false);
  }

  publishPreview() {
    const url = environment.apiUrl.replace('api', 'admin');

    if (environment.isLocal) {
      //need for testing localy
      window.opener.postMessage('roundPublish', 'http://localhost:8080/');
    } else {
      window.opener.postMessage('roundPublish', url);
    }
    window.close();
  }


}

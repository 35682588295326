import { Component, OnDestroy, OnInit } from '@angular/core';
import {interval, Subject } from "rxjs";
import { MaintenanceControlService } from "./core/services/maintenance-control.service";
import { LocalStorageService } from "./core/services/local-storage.service";
import { AuthService } from "./core/services/auth.service";
import { GameService } from "./core/services/game.service";
import {filter, switchMap, take, takeUntil} from "rxjs/operators";
import {PlatformDetectorService} from "./core/plaform-detector/platform-detector.service";
import { LoaderControllerService } from './core/services/loader-controller.service';

@Component({
  selector: 'hun-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private sseService: MaintenanceControlService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private gameService: GameService,
    private platformDetectorService: PlatformDetectorService,
    public loaderControllerService: LoaderControllerService
  ) {
  }
  ngOnInit(): void {
    this.userDataUpdate();
    this.loaderControllerService.isLoading$
      .pipe(
        filter(isLoading => !isLoading),
        take(1)
      )
      .subscribe(isLoading => {
        const initialLoader = document.getElementById('initial-loader');
        if (initialLoader) {
          initialLoader.style.display = 'none';
        }
      });

    this.platformDetectorService.isSafari$
      .pipe(
        filter(isSafari => isSafari),
        takeUntil(this.unsubscribe$))
      .subscribe(() => document.body.classList.add('safari-browser'))
    this.authService.processIsRestrictedUser();
    this.sseService.maintenanceControl();
    this.localStorageService.removeDefaultUserTokenToken();
    if (!this.gameService.isPreviewGame$.value) {
      this.localStorageService.deletePreviewAnswers();
    }

    this.authService.enableLogoutAfterClose$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value) {
          window.addEventListener('beforeunload', () => {
            window.opener.postMessage('childWindowClosed', '*');
          })
        }
    })
  }

  userDataUpdate() {
    interval(120000)
      .pipe(
        switchMap(() => this.authService.isAuthFlow$),
        filter((isAuthFlow) =>  !isAuthFlow),
        switchMap(() => this.authService.userDataUpdate()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }


  ngOnDestroy() {
    this.sseService.closeConnection();
    this.sseService.maintenanceDestroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import {CanActivateFn, Router} from '@angular/router';
import {environment} from "../../../environments/environment";
import {inject} from "@angular/core";

export const AllowManualSignInGuard: CanActivateFn = () => {
	const router = inject(Router);
	const isTestTenant = window.location.href.includes('test');
	if (environment.production && !isTestTenant) {
		router.navigate(['frame'], {replaceUrl: true});
		return false;
	}

	return true;
};

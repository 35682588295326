import { Injectable } from '@angular/core';
import { catchError, delayWhen, filter, finalize, retryWhen, switchMap, take } from "rxjs/operators";
import { BehaviorSubject, combineLatest, from, Observable, of, throwError, timer } from "rxjs";
import { CmsContentService } from './cms-content.service';
import { SaasSetingsService } from './saas-setings.service';
import { LanguageService } from "./language.service";
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { AuthValidationService_v3 } from "../../app-common/services/auth-validation-service_v3.service";
import { GameService } from "./game.service";
import { HashService } from "./hash.service";
import {GAnalyticsService} from "../../app-common/services/google-analitics.service";

@Injectable({
  providedIn: 'root'
})
export class AppInitializeService {

  appInitializing$ = new BehaviorSubject(false);

  constructor(
    private saasSetingsService: SaasSetingsService,
    private cmsContentService: CmsContentService,
    private languageService: LanguageService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private authValidationService_v3: AuthValidationService_v3,
    private gameService: GameService,
    private hashService: HashService,
    private gAnalyticsService: GAnalyticsService,
  ) { }

  init_2(): Observable<any> {
    //Need to be done this way, because router init after APP_INITIALIZER works,
    // and as well in APP_INITIALIZER we call api, this api use access token
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('accessToken');
    const userToken = urlParams.get('userToken');
    this.gameService.isPreviewGame$.next(!!(accessToken && userToken));
    this.localStorageService.setDefaultUserTokenToken(userToken);
    if (!accessToken && !userToken) {
      this.gAnalyticsService.frameLoaded();
    }
    this.appInitializing$.next(true);
    return this.getLocale().pipe(
      switchMap((locale: string) => {
        this.languageService.setLocale(locale);
        return combineLatest([
          this.authValidationService_v3.checkNoauthUserAvailability(),
          this.hashService.getHash(),
        ]).pipe(
          retryWhen(errors => {
              return errors.pipe(
                filter(error =>  error?.status === 0),
                take(3),
                delayWhen(val =>  timer(val * 1000))
              )
            }
          ),
          switchMap(([,hashConfig]) => {
            return combineLatest([
              this.cmsContentService.loadCmsContent(hashConfig.contentHash, locale).pipe(
                catchError(error => {
                  console.error('Error loading CMS content:', error);
                  return throwError(() => new Error('Failed to load CMS content'));
                })
              ),
              this.saasSetingsService.loadSettings(hashConfig.settingsHash).pipe(
                catchError(error => {
                  console.error('Error loading SaaS settings:', error);
                  return throwError(() => new Error('Failed to load SaaS settings'));
                })
              ),
            ]);
          }),
          take(1),
          finalize(() => {
            this.appInitializing$.next(false);
            this.localStorageService.setLocale(locale);
          }),
          catchError(error => {
            return throwError(error)
          })
        );
      }),
      catchError(error => {
        return throwError(error);
      })
    );

  }

  getLocale() {
    if (this.router.url === '/') {
      const url = new URL(window.location.href);
      const locale = url.searchParams.get('locale');
      if (locale === 'null') return of(null);
      return of(locale);
    }
    return from(this.localStorageService.getLocale())
  }
}

import { GameDetailModel } from "./Game";
import { GameStatus } from "../enums/game-status";

export class RoundItemDetailsModel {
  round: GameDetailModel
  isSingleEventRound: boolean
  isDataProcessingDisallow: boolean
  isHistoryDisallow: boolean
  gameButtonText: string
  gameButtonColor: string
  gameButtonTextColor: string
  imageUrl: string
  gameStatus: GameStatus
  nextRoundOpensAt?: string;
  name?: string
  prizeLabel: string
  gameButtonBorderColor?: string
  labelTextContent?: string
  labelIconContent?: string
  bonusText?: string
  prizeText?: string

}

export class RoundSetDetailsModel {
  [key: number]: RoundItemDetailsModel
}

import {Injectable} from '@angular/core';
import {OutsideMessageServices} from "./outside-messages.services";
import {AnalyticsEventEnum} from "../enums/AnalyticsEventEnum";
import {AnalyticsParametersModel} from "../models/BannerAnalyticsEvent";

@Injectable({
	providedIn: 'root'
})
export class GAnalyticsService {

	constructor(
		private outsideMessageServices: OutsideMessageServices
	) {
	}

	toPlay(parameters) {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.PLAY, parameters});
	}

	submitGame(parameters) {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.SUBMIT_GAME, parameters});
	}

	selectAnswer(parameters) {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.ANSWER_CLICK, parameters});
	}

	bannerClick(parameters) {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.BANNER_CLICK, parameters});
	}

	frameLoaded() {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.FRAME_LOADED});
	}

	processEventPlatformSensitive(parametersBody) {
		if ((window as any)?.android) {
			this.outsideMessageServices.sendMessageToAndroidParent(this.getJsonParameters(parametersBody));
		} else if ((window as any)?.webkit?.messageHandlers?.gameHandler) {
			this.outsideMessageServices.sendMessageToIOSParent(this.getJsonParameters(parametersBody));
		} else {
			this.sendEvent(parametersBody);
		}
	}

	getJsonParameters(parametersBody) {
		const { parameters } = parametersBody;
		if (parameters) {
			Object.keys(parameters).forEach(key => parameters[key] = parameters[key].toString());
			parametersBody.parameters = JSON.stringify(parameters);
		}
		return parametersBody;
	}

	sendEvent(data) {
		if (window && (window as any).dataLayer) {
			(window as any).dataLayer.push(data);
		}
	}
}

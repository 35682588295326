import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { UriConfig } from "../../app.config";
import {BehaviorSubject, Observable, of} from "rxjs";
import { LocalStorageService } from "./local-storage.service";
import { User } from "../../app-common/models/User";
import { jwtDecode } from "jwt-decode";
import { UserStateEnum } from 'src/app/app-common/enums/userStateEnum';
import {filter, map, switchMap, tap} from "rxjs/operators";
import { UserService } from "./user.service";
import { QuestionService } from "./question.service";
import { Router } from "@angular/router";
import { GameService } from "./game.service";
import { TenantService } from "../../app-common/services/tenant.services";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions = {
    headers: new HttpHeaders({responseType: 'text'}),
  }

  currentUser$ = new BehaviorSubject<boolean>(false);

  areBannedUserAllowedPlayGame$ = new BehaviorSubject<boolean>(false);

  isPreviewUsesOnlyUserToken$ = new BehaviorSubject(false);

  isInternalSignIn$ = new BehaviorSubject(false);

  enableLogoutAfterClose$ = new BehaviorSubject<boolean>(false);

  _isFromIntegration = false;

  restorePasswordToken$ = new BehaviorSubject<string>(null);

  isAuthFlow$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private questionService: QuestionService,
    private router: Router,
    private gameService: GameService,
    private tenantService: TenantService,
  ) {
  }

  userSignIn(user: User): Observable<{ accessToken: string }> {
    return this.http.post<{ accessToken: string }>(this.uriConfig.login, user, this.httpOptions).pipe(
      tap((data) => {
        this.processUser(data);
      })
    )
  }

  processUser(data) {
    this.isInternalSignIn$.next(true);
    this.localStorageService.setToken(data.accessToken);
    this.processCurrentUser();
    this.processIsRestrictedUser();
  }


  setSentryUser(user: sentryUser) {
    const {email} = user;
    Sentry.setUser({email});
  }

  getCurrentUser(): string {
    return this.localStorageService.getToken();
  }

  getDefaultUser() {
    return this.localStorageService.getDefaultUserTokenToken()
  }


  getIsCurrentUser() {
    return this.currentUser$;
  }

  processCurrentUser() {
    const accessToken = this.getCurrentUser();
    const defaultUserToken = this.localStorageService.getDefaultUserTokenToken();
    this.currentUser$.next(!!(accessToken || defaultUserToken));
  }

  processIsRestrictedUser() {
    const accessToken = this.getCurrentUser();
    if (accessToken) {
      const { state } = jwtDecode(accessToken) as any || {};
      this.userService.isRestrictedUser$.next(state === UserStateEnum.LOCKED && !this.gameService.isPreviewGame$.value && !this.areBannedUserAllowedPlayGame$.value);
    } else {
      this.userService.isRestrictedUser$.next(false);
    }
  }

  initUser() {
    this.processCurrentUser();
    return this.getIsCurrentUser().pipe(
      switchMap(response => {
        if (!response) return of(null);
        return this.getCurrentUser();
      }),
      filter(response => !!response),
      map(user => {
        this.userService.setUserDetails(user);
        return of(true);
      })
    )
  }

  checkSavedSetitngs() {
    const selectedAnswers = this.localStorageService.getSelectedAnswers();
    if (selectedAnswers) {
      this.questionService.questionAnswersList$.next(selectedAnswers);
      this.questionService.isAnswerWaitForSubmit$.next(true);

      this.router.navigate(['frame/my-predictions']);
      this.localStorageService.deleteSelectedAnswers();
    } else {
      this.tenantService.isStreak$.pipe(map(res => {
        let link = res ? '/frame/streak' : '/frame/landing';
        this.router.navigate([link], {replaceUrl: true});
      }))
    }
    return true;
  }

  processFrameIntegration(route) {
    return this.tenantService.isStreak$.pipe(
      map(isStreak => {
        let link = '/frame';
        if (isStreak) {
          link = link + '/streak'
        } else {
          link = link + '/landing'
        }

        const urlParams = new URLSearchParams();
        const secondTokenCheck = urlParams.get('token');
        if (route.queryParams['token'] || secondTokenCheck) {
          const token = route.queryParams['token'] ? route.queryParams['token'] : secondTokenCheck;

          link = `${link}?token=${token}`;
        }
        this.router.navigate([link], {replaceUrl: true});
        return this.checkSavedSetitngs();
      })
    )
  }

  get isFrameIntegration () {
    return this._isFromIntegration;
  }

  set isFrameIntegration (isFrameIntegration) {
    this._isFromIntegration = isFrameIntegration;
  }

  userDataUpdate() {
    return this.userService.getCurrentUser();
  }

  resetPassword(body) {
    return this.http.post(this.uriConfig.auth + '/reset-password', body);
  }

  signUp(body) {
    return this.http.post(this.uriConfig.auth + '/b2c/signup', body);
  }

  validateResetPassword(body): Observable<{ isValid: boolean }> {
    return this.http.post<{ isValid: boolean }>(this.uriConfig.auth + '/validate-reset-password', body);
  }

  confirmSignUp(token: string): Observable<{ accessToken: string }> {
    let params = new HttpParams();
    if (token) {
      params = params.append('token', token)
    }
    return this.http.post<{ accessToken: string }>(this.uriConfig.auth + '/b2c/signup/confirm', null, { params });
  }

  restorePassword(body) {
    return this.http.post(this.uriConfig.auth + '/restore-password', body);
  }
}

export interface sentryUser {
  email: string;
  role: string;
}
